import json from './config.json';

const sanitizeUrlEndSlash = (baseUrl: string) => baseUrl.replace(/\/+$/, '');

const constructFlowsiteApiUrl = (env: string, baseUrl: string) => {
  const sanitisedUrl = sanitizeUrlEndSlash(baseUrl);
  if (env !== 'local') {
    return sanitisedUrl.concat('/flowsite');
  }
  return sanitisedUrl;
};

export const environment = {
  production: json['NODE_ENV'] === 'production',
  environmentIdentifier: json['NODE_ENV'] !== 'production' ? json['NODE_ENV'] : '',
  fusionAuth: {
    authority: json['FUSIONAUTH_AUTHORITY'],
    clientId: json['FUSIONAUTH_FLOWSITE_APPLICATION_ID'],
    logLevel: 0,
  },
  flowsiteApiUrl: constructFlowsiteApiUrl(json['NODE_ENV'], json['SITEMATE_API_URL']),
  globalApiUrl: sanitizeUrlEndSlash(json['GLOBAL_API_URL']),
};
